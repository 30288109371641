import { find, some } from "lodash";

/*
default value: en

labelI18N: {
  it: "",
  es: "",
  fr: "",
  de: "",
},

preLabelI18N: {
  it: "",
  es: "",
  fr: "",
  de: "",
},
*/

export const sezioni = [
  // SECTION  1
  {
    domande: [
      {
        key: "activities",
        label: "What are you doing right now?",
        labelI18N: {
          it: "Cosa sta facendo in questo momento?",
          es: "¿Qué está haciendo en este momento?",
          fr: "Que faites-vous en ce moment?",
          de: "Was machen Sie gerade?"
        },
        type: "multi-choice",
        options: [
          {
            label: "Sleeping",
            value: 1,
            labelI18N: {
              it: "Sto per dormire",
              es: "Durmiendo",
              fr: "Dormir",
              de: "Schlafen"
            }
          },
          {
            label: "Stay sick in bed",
            value: 2,
            labelI18N: {
              it: "Sono ammalato a letto",
              es: "En cama debido a enfermedad",
              fr: "Rester au lit, malade",
              de: "Krank im Bett liegen"
            }
          },
          {
            label: "Eat / drink / breakfast / snack",
            value: 3,
            labelI18N: {
              it: "Mangiando/bevendo/ facendo merenda",
              es: "Comiendo/bebiendo/desayunando/merendando",
              fr: "Manger/boire / petit-déjeuner/Gouter",
              de: "Essen / Trinken / Frühstück / Snack"
            }
          },
          {
            label: "Self-care (washing, dressing, etc.)",
            value: 4,
            labelI18N: {
              it: "Cura personale (mi sto lavando, vestendo, ...)",
              es: "Cuidado personal (lavándome, vistiéndome, etc.)",
              fr: "Prendre soin de soi (se laver, s’habiller, etc.)",
              de: "Selbstfürsorge (Körperpflege, Ankleiden etc.)"
            }
          },
          {
            label: "Work or do internship / internship (or look for work)",
            value: 5,
            labelI18N: {
              it: "Lavorando o facendo tirocinio ",
              es: "Trabajando o haciendo un aprendizaje (o buscando trabajo)",
              fr: "Travailler ou faire un stage (ou chercher du travail)",
              de: "Arbeiten / Praktikum / nach Arbeit suchen "
            }
          },
          {
            label: "Studying / attending training courses",
            value: 6,
            labelI18N: {
              it: "Studiando/Frequentando le lezioni ",
              es: "Estudiando/asistiendo a cursos de formación",
              fr: "Etudier /suivre des cours de formation",
              de: "Lernen / an Unterricht oder Weiterbildung teilnehmen "
            }
          },
          {
            label: "Cleaning, cooking, tidying up the house or car, shopping",
            value: 7,
            labelI18N: {
              it: "Pulendo/Cucinando/Sistemando la casa/Facendo compere",
              es:
                "Limpiando, cocinando, ordenando la casa o el coche, comprando",
              fr:
                "Nettoyer, cuisiner, ranger la maison ou la voiture, shopping",
              de: "Putzen, Kochen, Aufräumen, Einkaufen "
            }
          },
          {
            label:
              "Take care of someone or something (people, animals, plants)",
            value: 8,
            labelI18N: {
              it:
                "Mi sto prendendo cura di qualcuno (persona, animale, pianta)",
              es: "Cuidando de alguien o algo (personas, animales, plantas)",
              fr:
                "Prendre soin de quelqu’un ou de quelque chose (personnes, animaux, plantes)",
              de:
                "Sich um jemanden oder etwas kümmern (Verwandte/Freunde/Haustiere/Pflanzen)"
            }
          },
          {
            label: "Voluntary work",
            value: 9,
            labelI18N: {
              it: "Volontariato",
              es: "Trabajo voluntario",
              fr: "Travail bénévole ",
              de: "Freiwilligen Arbeit"
            }
          },
          {
            label:
              "Leisure activities (e.g. social life, playing, chatting, reading, going to the cinema, playing an instrument, etc.)",
            value: 10,
            labelI18N: {
              it:
                "Attività di svago (stare in compagnia, giocare, chiacchierare, leggere, andare al cinema, suonare uno strumento etc.)",
              es:
                "Actividades de ocio (p. ej., vida social, jugando, charlando, leyendo, yendo al cine, tocando un instrumento, etc.)",
              fr:
                "Activités de loisirs (e.g. vie sociale, jouer, discuter, lire, aller au cinéma, jouer d’un instrument, etc.)",
              de:
                "Freizeitaktivitäten (z.B. Spiel spielen, Lesen, Kino, Instrument spielen, Freunde Treffen etc.)"
            }
          },
          {
            label: "Thinking, resting, doing nothing (without sleeping)",
            value: 11,
            labelI18N: {
              it: "Pensando, riposando, non fare nulla (senza dormire)",
              es: "Pensando, descansando, no haciendo nada (que no sea dormir)",
              fr: "Réfléchir, se reposer, ne rien faire (sans dormir)",
              de: "Nachdenken, Ausruhen, Nichts-Tun ohne zu schlafen "
            }
          },
          {
            label: "Doing sports, physical activity",
            value: 12,
            labelI18N: {
              it: "Facendo sport, attività sportiva",
              es: "Haciendo deporte, actividad física",
              fr: "Faire du sport, une activité physique ",
              de: "Sport, körperliche Aktivität"
            }
          },
          {
            label:
              "Getting around (on foot or by bicycle, car, public transport)",
            value: 13,
            labelI18N: {
              it:
                "Sono in giro (a piedi o in bicicletta, macchina, sui mezzi pubblici)",
              es:
                "Desplazándome (a pie o en bicicleta, coche, transporte público)",
              fr:
                "Se déplacer (à pied, à vélo, en voiture, en transports en commun)",
              de:
                "Fortbewegung (zu Fuß, mit dem Fahrrad, Auto, Öffentliche Verkehrsmittel) "
            }
          },
          {
            label: "Watch TV / listen to radio or podcast",
            value: 14,
            labelI18N: {
              it:
                "Guardando la TV / ascoltando la radio, la musica o un podcast",
              es: "Viendo la televisión / escuchando la radio/música/ podcast",
              fr: "Regarder la télévision / écouter la radio/musique/ podcast",
              de: "Fernsehe, Radio/Musik/Podcast hören "
            }
          },
          {
            label:
              "Participate in religious activities (e.g. go to mass, pray, etc.)",
            value: 15,
            labelI18N: {
              it:
                "Sto partercipando ad attività religiose (sto andando in chiesa, sto pregando)",
              es:
                "Participando en actividades religiosas (p. ej., yendo a misa, rezando, etc.)",
              fr:
                "Participer à des activités religieuses (e.g. aller à la messe, prier, etc.)",
              de:
                "Teilnahme an religiösen Veranstaltungen (z.B. Beten, Gottesdienst oder ähnliches)"
            }
          }
        ]
      },
      {
        key: "interpersonal_relationships",
        label: "Who are you with right now?",
        labelI18N: {
          it: "Con chi si trova adesso?",
          es: "¿Con quién está en este momento?",
          fr: "Avec qui êtes-vous en ce moment?",
          de: "Mit wem sind Sie gerade zusammen?"
        },
        type: "single-choice",
        options: [
          {
            label: "Alone",
            value: 16,
            labelI18N: {
              it: "Solo",
              es: "Solo",
              fr: "Seul(e)",
              de: "Alleine"
            }
          },
          {
            label: "With other people",
            value: 17,
            labelI18N: {
              it: "Con altre persone",
              es: "Con otras personas",
              fr: "Avec d’autres personnes",
              de: "Mit anderen Menschen "
            }
          }
        ]
      }
    ]
  },

  // SECTION  2
  {
    domande: [
      {
        key: "location",
        label: "Where are you with right now?",
        labelI18N: {
          it: "Dove si trova in questo momento?",
          es: "¿Dónde está en este momento?",
          fr: "Avec qui êtes-vous en ce moment?",
          de: "Wo befinden Sie sich gerade? "
        },
        type: "single-choice",
        options: [
          {
            label: "At home",
            labelI18N: {
              it: "A casa",
              es: "En casa",
              fr: "A la maison",
              de: "Zu Hause"
            },
            value: 1
          },
          {
            label: "At work/school/university",
            labelI18N: {
              it: "Al lavoro/scuola/università",
              es: "En el trabajo/escuela/universidad",
              fr: "Au travail/à l’école/à l’université",
              de: "Bei der Arbeit/Schule/Universität"
            },
            value: 2
          },
          {
            label: "In a shop",
            labelI18N: {
              it: "In un negozio",
              es: "En una tienda",
              fr: "Dans un magasin ",
              de: "In einem Geschäft"
            },

            value: 3
          },
          {
            label:
              "Moving from one place to another (on a public/private transport, see bus, underground, taxi, etc)",
            labelI18N: {
              it:
                "Mi sto muovendo da un luogo ad un altro luogo (con trasporto privato/ con trasporto pubblico come pullman, metropolitana, treno etc)",
              es:
                "Desplazándome de un lugar a otro (en transporte público/privado, como autobús, metro, taxi, etc.)",
              fr:
                "En déplacement d’un endroit à un autre (dans les transports publics /privés, le métro, le taxi, etc.)",
              de:
                "Auf dem Weg von einem zum anderen Ort ( z.B. mit ÖPNV/Taxi/Auto)"
            },
            value: 4
          },
          {
            label: "In a leisure place (e.g., bar, cinema, sport facility)",
            labelI18N: {
              it: "In un luogo di svago (bar, cinema, palestra)",
              es:
                "En un lugar de ocio (p. ej., bar, cine, instalaciones deportivas)",
              fr:
                "Dans un lieu de loisirs (e.g., bar, cinema, un centre sportif)",
              de: "In einer Freizeit-Einrichtung (z.b. Kino, Bar, Sportstätte)"
            },
            value: 5
          },
          {
            label:
              "In a health facility (e.g., hospital, outpatient clinic, etc)",
            labelI18N: {
              it: "In un luogo di cura ( ospedale, ambulatorio, clinica, etc)",
              es:
                "En un centro de salud (p. ej., hospital, clínica externa, etc.)",
              fr:
                "Dans un établissement de santé (e.g., hôpital, clinique externe, etc)",
              de:
                "In einer Einrichtung des Gesundheitssystems (z.B. Krankenhaus, Arztpraxis)"
            },
            value: 6
          }
        ]
      }
    ]
  },

  // SECTION  3
  {
    randomSort: true,
    domande: [
      {
        label: "Content",
        labelI18N: {
          it: "Contento",
          es: "Contento/a",
          fr: "Content",
          de: "Zufrieden"
        },
        key: "emotion_content"
      },
      {
        label: "Down",
        labelI18N: {
          it: "Abbattuto",
          es: "Deprimido/a",
          fr: "Triste",
          de: "Gedrückter Stimmung"
        },
        key: "emotion_down"
      },
      {
        label: "Irritated",
        labelI18N: {
          it: "Irritatato",
          es: "Irritado/a",
          fr: "Irrité",
          de: "Gereizt"
        },
        key: "emotion_irritated"
      },
      {
        label: "Cheerful",
        labelI18N: {
          it: "Allegro",
          es: "Alegre",
          fr: "Joyeux",
          de: "Fröhlich"
        },
        key: "emotion_cheerful"
      },
      {
        label: "Restless",
        labelI18N: {
          it: "Irrequieto",
          es: "Inquieto/a",
          fr: "Agité",
          de: "Unruhig"
        },

        key: "emotion_restless"
      },
      {
        label: "Exhausted",
        labelI18N: {
          it: "Esausto ",
          es: "Agotado/a",
          fr: "Exténué",
          de: "Erschöpft"
        },

        key: "emotion_exhausted"
      },
      {
        label: "Full of energy",
        labelI18N: {
          it: "Pieno di energie",
          es: "Lleno/a de energía",
          fr: "Plein d’énergie",
          de: "Voller Energie"
        },

        key: "emotion_full_of_energy"
      }
    ].map(stato => ({
      preLabel: "Right now, I feel...",
      preLabelI18N: {
        it: "In questo momento, quanto si sente",
        es: "En este momento, me siento...",
        fr: "En ce moment, je me sens...",
        de: "Jetzt gerade fühle ich mich..."
      },
      key: stato.key,
      label: stato.label,
      labelI18N: stato.labelI18N,
      type: "barra0-100"
    }))
  },

  // SECTION  3
  {
    sectionFilterKey: "domandaGiornataSpeciale_typicalDay",
    domande: [
      {
        key: "typical_day",
        label:
          "Thinking about today, would you say it was a typical day for you?",
        labelI18N: {
          it:
            "Pensando a oggi, direbbe che è stata una giornata tipica per lei?",
          es: "Pensando en hoy, ¿diría que fue un día típico para usted?",
          fr:
            "En pensant à la journée d’aujourd’hui, diriez-vous qu’il s’agit d’une journée typique pour vous?",
          de:
            "Wenn Sie über den heutigen Tag nachdenken, würden Sie sagen, dass heute ein typischer Tag für Sie war?"
        },

        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ]
      },
      {
        key: "not_typical_day_why",
        label: "Specify the reason:",
        labelI18N: {
          it: "Descriva la motivazione",
          es: "Especifique la razón:",
          fr: "Précisez la raison- :",
          de: "Bitte geben Sie den Grund hierfür an:"
        },
        type: "text",
        condition: {
          key: "typical_day",
          value: 2
        }
      },
      {
        key: "caffeine",
        label: "Did you consume caffeine today?",
        labelI18N: {
          it: "Ha bevuto caffè oggi?",
          es: "¿Consumió cafeína hoy?",
          fr: "Avez-vous consommé de la caféine aujourd’hui ?",
          de: "Haben Sie heute Koffein konsumiert?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ]
      },
      {
        key: "caffeine_quantity",
        label: "How many cups?",
        labelI18N: {
          it: "Quante tazze?",
          es: "¿cuántas tazas? ",
          fr: "combine de tasses ? ",
          de: "wie viele Tassen?"
        },
        type: "text",
        condition: {
          key: "caffeine",
          value: 1
        }
      },
      {
        key: "nicotine",
        label: "Did you use nicotine today?",
        labelI18N: {
          it: "Ha fumato sigarette oggi?",
          es: "¿Consumió nicotina hoy?",
          fr: "Avez-vous consommé de la nicotine aujourd’hui ?",
          de: "Haben Sie heute Nikotin konsumiert?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ]
      },
      {
        key: "alcohol",
        label: "Did you drink alcohol today?",
        labelI18N: {
          it: "Hai bevuto bevande alcoliche oggi?",
          es: "¿Consumió alcohol hoy?",
          fr: "Avez-vous consommé de l’alcool aujourd’hui ?",
          de: "Haben Sie heute Alkohol getrunken?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ]
      }
    ]
  },

  // SECTION  4
  {
    sectionFilterKey: "domandaGiornataSpeciale_sleep",
    domande: [
      {
        key: "sleep",
        label: "Did you sleep well?",
        labelI18N: {
          it: "Ha dormito bene? ",
          es: "¿Durmió bien?",
          fr: "Avez-vous bien dormi? ",
          de: "Haben Sie gut geschlafen?"
        },

        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ]
      },
      {
        key: "sleep_hours",
        label: "How many hours did you sleep?",
        labelI18N: {
          it: "Quante ore ha dormito questa notte?",
          es: "¿Cuántas horas durmió?",
          fr: "Combien d’heures avez-vous dormi?",
          de: "Wie viele Stunden haben sie etwa geschlafen? "
        },
        type: "text"
      },
      {
        key: "time_before_sleep",
        label:
          "How long did it take before you fell asleep last night? (in minute)",
        labelI18N: {
          it: "Quanto tempo ha impiegato ad addormentarsi? (in minuti)",
          es: "¿Cuánto tiempo tardó en dormirse anoche? (en minutos) ",
          fr:
            "Combien de temps vous a-t-il fallu pour vous endormir la nuit dernière? (en minute) ",
          de:
            "Wie lange hat es gedauert bis Sie gestern eingeschlafen sind (in Minuten)? "
        },
        type: "text"
      },
      {
        key: "wake_up_night",
        label: "How often did you wake up last night?",
        labelI18N: {
          it: "Quante volte si è svegliato durante la notte?",
          es: "¿Con qué frecuencia se despertó anoche? ",
          fr: "Combien de fois vous êtes-vous réveille(e) la nuit dernière ? ",
          de: "Wie oft sind Sie in der letzten Nacht aufgewacht? "
        },
        type: "text"
      }
    ]
  },

  // SECTION  5
  {
    sectionFilterKey: "domandaGiornataSpeciale_after_eat",
    domande: [
      {
        key: "eaten",
        label: "Have you eaten since your last check-in?",
        labelI18N: {
          it: "Dall’ultima volta che ha aperto questa app, ha mangiato?",
          es: "¿Ha comido desde su última notificación?",
          fr: "Avez-vous mangé depuis votre dernière visite?",
          de: "Haben Sie seit dem letzten Check-in etwas gegessen?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ]
      },
      {
        key: "eaten_feel",
        label: "How do you feel after this meal?",
        labelI18N: {
          it: "Come si è sentito dopo il pasto?",
          es: "¿Cómo se siente después de esta comida?",
          fr: "Comment vous sentez-vous après ce repas?",
          de: "Wie fühlen Sie sich nach dieser Mahlzeit?"
        },
        type: "single-choice",
        options: [
          {
            label: "I am still hungry",
            labelI18N: {
              it: "Avevo ancora fame",
              es: "Todavía tengo hambre",
              fr: "J’ai encore faim",
              de: "Ich bin noch hungrig"
            },
            value: 1
          },
          {
            label: "I am full",
            labelI18N: {
              it: "Ero sazio",
              es: "Estoy lleno/a",
              fr: "Je suis rassasié(e)",
              de: "Ich bin satt"
            },
            value: 2
          },
          {
            label: "I am unpleasantly full",
            labelI18N: {
              it: "Ero spiacevolmente sazio ",
              es: "Me siento desagradablemente lleno/a",
              fr: "Je suis désagréablement rassasié(e)",
              de: "Ich fühle mich unangenehm gesättigt"
            },
            value: 3
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_overeat",
        label: "Did you overeat?",
        labelI18N: {
          it: "Pensa di aver mangiato più del dovuto?",
          es: "¿Comió en exceso?",
          fr: "Avez-vous trop mangé?",
          de: "Haben Sie zu viel gegessen?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_loss_of_control",
        label: "Did you experience a loss of control during this meal?",
        labelI18N: {
          it: "Ha sperimentato una perdita di controllo durante il pasto?",
          es: "¿Experimentó una pérdida de control durante esta comida?",
          fr: "Avez-vous ressenti une perte de contrôle au cours de ce repas?",
          de: "Haben Sie während der Mahlzeit einen Kontrollverlust erlebt?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_guilty",
        label: "Did you feel guilty after this meal?",
        labelI18N: {
          it: "Si è sentito in colpa dopo questo pasto?",
          es: "¿Se sintió culpable después de esta comida?",
          fr: "Vous êtes-vous senti coupable après ce repas?",
          de: "Haben Sie sich nach der Mahlzeit schuldig gefühlt?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_tried_something",
        label:
          "Have you tried doing something to eliminate calories taken in during this meal?",
        labelI18N: {
          it:
            "Ha provato a fare qualcosa per eliminare le calorie assunte con questo pasto?",
          es:
            "¿intentó hacer algo para eliminar las calorías consumidas durante esta comida?",
          fr: "qu’avez-vous fait ou qu’avez-vous essayé de faire ?",
          de:
            "haben Sie versucht, etwas zu tun, um die während dieser Mahlzeit aufgenommenen Kalorien zu reduzieren?"
        },
        type: "single-choice",
        options: [
          {
            label: "Yes",
            labelI18N: {
              it: "Sì",
              es: "Sí",
              fr: "Oui",
              de: "Ja"
            },
            value: 1
          },
          {
            label: "No",
            labelI18N: {
              it: "No",
              es: "No",
              fr: "Non",
              de: "Nein"
            },
            value: 2
          }
        ],
        condition: {
          key: "eaten_guilty",
          value: 1
        }
      },
      {
        key: "eaten_tried_something_list",
        label: "What did you do or what did you try to do?",
        labelI18N: {
          it: "Che cosa ha fatto o che cosa ha provato a fare?",
          es: "¿qué hizo o intentó hacer?",
          fr: "qu’avez-vous fait ou qu’avez-vous essayé de faire ?",
          de: "was haben Sie versucht?"
        },
        condition: {
          key: "eaten_tried_something",
          value: 1
        },
        type: "multi-choice",
        options: [
          {
            label: "Vomiting",
            labelI18N: {
              it: "Vomitare",
              es: "Vómito(s)",
              fr: "Vomir",
              de: "Erbrechen"
            },
            value: 1
          },
          {
            label: "Fasting or eating less",
            labelI18N: {
              it: "Digiunare o	mangiare meno",
              es: "Ayuno o comer menos",
              fr: "Jeûner ou manger mois",
              de: "Fasten oder weniger essen"
            },
            value: 2
          },
          {
            label: "Using laxatives",
            labelI18N: {
              it: "Assumere lassativi",
              es: "Usar laxantes",
              fr: "Utilisation de laxatifs",
              de: "Abführmittel"
            },
            value: 3
          },
          {
            label: "Compulsory physical activity",
            labelI18N: {
              it: "Attività fisica in grandi quantità",
              es: "Actividad física obligatoria",
              fr: "Activité physique obligatoire",
              de: "Obligatorische körperliche Aktivität"
            },
            value: 4
          }
        ]
      }
    ]
  }
];

export function getPrimaDomandaNonRisposta(notifica, sezioni, risposte, user) {
  let domandaAttuale = null;
  find(sezioni, s => {
    // rimuove domande speciali
    if (s.sectionFilterKey && !notifica[s.sectionFilterKey]) {
      return false;
    }
    return find(s.domande, d => {
      if (risposte[d.key] == null) {
        if (d.condition) {
          const rispostaAltraDomanda = risposte[d.condition.key];
          if (rispostaAltraDomanda != d.condition.value) {
            return false;
          }
        }
        domandaAttuale = d;
        return true;
      }
      return false;
    });
  });

  return domandaAttuale;
}

export function checkCompletato(notifica, user) {
  const risposte = notifica.risposte || {};
  let domandaAttuale = getPrimaDomandaNonRisposta(
    notifica,
    sezioni,
    risposte,
    user
  );
  if (!domandaAttuale && !notifica.dataCompilazione) {
    notifica.dataCompilazione = new Date().toISOString();
  }
}

function getKeyDayPrefix(key) {
  return key.replace(/_n_[0-9]+/, "");
}

function isSameDay(key1, key2) {
  return getKeyDayPrefix(key1) === getKeyDayPrefix(key2);
}
