import React from "react";
import { Component } from "react";
import { Row, Col, Card, CardHeader, CardBody, Alert, Table } from "reactstrap";
import Statistiche from "app/components/Statistiche.js";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { getLoginSessions } from "app/actions";
import { map, get } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  NAME,
  DESCRIPTION,
  ICON,
  EMAIL_PER_ACCESSO_ANOMALO
} from "common/datiPortale";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <div className="home-page-titolo-studio">
            <img
              style={{ width: 250, maxWidth: "80%", marginBottom: 30 }}
              src={ICON}
            />

            <h1>{NAME} </h1>

            <h4>{DESCRIPTION} </h4>

            <h5> </h5>
          </div>
        </Col>

        <Col sm={12} xl={7}>
          <Card>
            <CardHeader>{t("statistiche_label")} </CardHeader>

            <CardBody>
              <Statistiche />
            </CardBody>
          </Card>
        </Col>

        <Col sm={12} xl={5}>
          <Card>
            <CardHeader>{t("login_effettuati_label")} </CardHeader>

            <CardBody className="table-login-effettuati">
              <AsyncData loaderAction={this.props.getLoginSessions}>
                {({ loading, loadingError, data }) => {
                  console.log({ loading, loadingError, data });
                  if (loading) {
                    return <Loading />;
                  }
                  if (loadingError) {
                    return <Alert color="danger">{loadingError.message}</Alert>;
                  }
                  return (
                    <Table responsive={true} bordered={true}>
                      <thead>
                        <tr>
                          <th>{t("login_effettuati_table_col_data")} </th>
                          <th>IP </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(data, (row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>
                              {get(row, "loginTimestamp")
                                ? moment(get(row, "loginTimestamp")).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                : null}
                            </td>
                            <td>{row.ip}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  );
                }}
              </AsyncData>

              <div className="login-anomalo-info">
                {t("accesso_anomalo_contatti_label")}{" "}
                <div>{EMAIL_PER_ACCESSO_ANOMALO}</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  getLoginSessions: getLoginSessions
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Main)
);
