import React from "react";
import { Component } from "react";
import { Input, Row, Col, Button, Progress } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class CaricaDatiActigrafo extends Component {
  static propTypes = {
    paziente: PropTypes.object,
    close: PropTypes.func,
    updatePaziente: PropTypes.func
  };

  state = {
    loading: false,
    file: null
  };

  onFile = e => {
    const file = e.target.files[0];

    // max 2 MB size allowed
    // if(file.size > 2*1024*1024) {
    // 	alert('Error : Exceeded size 2MB');
    // 	return;
    // }

    this.setState({
      file
    });
  };

  upload = () => {
    const url = `/api/pazienti/${this.props.paziente.id}/uploadDatiActigrafo`;

    var data = new FormData();

    this.setState({
      loading: true,
      progress: 0
    });

    data.append("file", this.state.file);

    var request = new XMLHttpRequest();

    request.open("post", url);

    // upload progress event
    request.upload.addEventListener("progress", e => {
      var percent_complete = (e.loaded / e.total) * 100;

      // Percentage of upload completed
      console.log(percent_complete);
      this.onUploadProgress(percent_complete);
    });

    // AJAX request finished event
    request.addEventListener("load", e => {
      // HTTP status message
      console.log(request.status);

      // request.response will hold the response from the server
      console.log(request.response);

      this.onUploadEnd(request.status, request.response);
    });

    // send POST request to server side script
    request.send(data);
  };

  onUploadProgress(percent_complete) {
    this.setState({
      progress: percent_complete
    });
  }

  onUploadEnd(status, response) {
    if (status !== 200) {
      this.setState({
        progress: null,
        loading: false
      });

      alert("Errore nel caricare il file");
    } else {
      this.setState({
        progress: 100
      });

      // alert('File caricato con successo!')

      //TODO: only if success
      setTimeout(() => {
        this.props.updatePaziente(JSON.parse(response).data);
        this.props.close();
      }, 500);
    }
  }

  render() {
    const { t, paziente, close } = this.props;
    const { loading, file, progress } = this.state;

    return (
      <div>
        <Row>
          <Col>{t("carica_dati_actigrafo_label")}:</Col>
        </Row>

        <Row style={{ marginTop: 50 }}>
          <Col>
            {t("dati_grezzi_file_label")}:
            <br />
            <br />
            <Input type="file" accept=".gt3x" onChange={this.onFile} />
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col>
            <Button
              color="primary"
              outline
              disabled={!file || loading}
              onClick={this.upload}
            >
              {t("carica_file_label")}
            </Button>
          </Col>
          <Col md="12" style={{ marginTop: 20 }}>
            {typeof progress === "number" && <Progress value={progress} />}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(CaricaDatiActigrafo);
