import React from "react";
import { Component } from "react";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  Alert,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody
} from "reactstrap";
import { getOperatore, updateOperatore } from "app/actions";
import { get } from "lodash";
import PreventClose from "app/elements/PreventClose.js";
import DeleteUser from "app/elements/DeleteUser.js";
import OperatoreForm from "app/components/OperatoreForm.js";
import LegendaColoriQuestionari from "app/elements/LegendaColoriQuestionari.js";
import ListaQuestionariUtente from "app/components/ListaQuestionariUtente.js";
import { questionariPerOperatore } from "common/utils/questionari.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import AbstractDettaglioPartecipante from "./AbstractDettaglioPartecipante";

class DettaglioOperatore extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <AbstractDettaglioPartecipante isOperatore={true} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: get(ownProps, "match.params.id")
  };
};
const mapDispatchToProps = {
  getOperatore: getOperatore,
  updateOperatore: updateOperatore
};

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DettaglioOperatore)
  )
);
