import React from "react";
import ReactDom from "react-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { tipiControlli } from "../../common/tipiPazienti";

class CreatedPopup extends React.Component {
  redirectToUser = () => {
    this.props.history.push(this.props.userUrl);
    this.props.onEnd();
  };

  redirectToLista = () => {
    this.props.history.push(this.props.listaUrl);
    this.props.onEnd();
  };

  render() {
    const { t, nameSing, namePlur, user } = this.props;

    return (
      <Modal isOpen={true}>
        <ModalBody>
          {t("il_codice_soggetto_creato_e")}:<h4>{user.codiceUtente}</h4>
          {t("nota_codice_soggetto")}
          {user.userType === "Operatore" && user.adminCentro && (
            <div style={{ marginTop: 30 }}>
              {t("credenziali_mostrate_label")}:<h4>{user.email}</h4>
              <h4>{user.password}</h4>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.redirectToUser}>
            {t("vai_alla_pagina")} {nameSing}
          </Button>
          {/* <Button color="secondary" onClick={this.redirectToLista}>
          Ritorna alla lista {namePlur}
        </Button> */}
        </ModalFooter>
      </Modal>
    );
  }
}

const CreatedPopup2 = withTranslation()(CreatedPopup);

export default function CreateRedirectPopup({ user, history, isOpen, onEnd }) {
  if (!isOpen) {
    return null;
  }

  let url;
  let nameSing, namePlur;
  if (tipiControlli.indexOf(user.userType) !== -1) {
    url = "/controlli/";
    nameSing = "controllo";
    namePlur = "controlli";
  } else if (user.userType === "Operatore") {
    url = "/operatori/";
    nameSing = "operatore";
    namePlur = "operatori";
  } else {
    url = "/pazienti/";
    nameSing = "paziente";
    namePlur = "pazienti";
  }

  const listaUrl = url;

  url += user.id + "/dettaglio";

  const el = (
    <CreatedPopup2
      user={user}
      history={history}
      userUrl={url}
      listaUrl={listaUrl}
      nameSing={nameSing}
      namePlur={namePlur}
      onEnd={onEnd}
    />
  );

  return el;
}
