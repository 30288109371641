import React from "react";
import { useMemo } from "react";
import PageSidebar from "app/pages/Sidebar";
import { useTranslation } from "react-i18next";
import {
  HAS_PARTECIPANTI_CONTROLLI,
  HAS_PARTECIPANTI_OPERATORI
} from "common/datiPortale";

export default function Sidebar(props) {
  const { t } = useTranslation();

  const sidebarItems = useMemo(() => {
    const sidebarItems = {
      items: [
        {
          name: "Home",
          url: "/",
          icon: "TODO: not-done-yet",
          attributes: {
            exact: true
          }
        },
        {
          name: t("sidebar_lista_pazienti"),
          url: "/pazienti",
          icon: "TODO: not-done-yet",
          attributes: {
            exact: true
          }
        },
        HAS_PARTECIPANTI_CONTROLLI && {
          name: t("sidebar_lista_controlli"),
          url: "/controlli",
          icon: "TODO: not-done-yet",
          attributes: {
            exact: true
          }
        },
        HAS_PARTECIPANTI_OPERATORI && {
          name: t("sidebar_lista_operatori"),
          url: "/operatori",
          icon: "TODO: not-done-yet",
          attributes: {
            exact: true
          }
        },
        {
          name: t("sidebar_esporta_dati"),
          url: "/dati/esporta",
          icon: "TODO: not-done-yet",
          attributes: {
            exact: true
          }
        },
        {
          name: t("sidebar_guide"),
          url: "/training",
          icon: "TODO: not-done-yet",
          attributes: {
            exact: true
          }
        }
      ].filter(x => x)
    };
    return sidebarItems;
  }, [t]);

  return <PageSidebar items={sidebarItems} />;
}
