import React from "react";
import { Component } from "react";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { Alert, Table, Button } from "reactstrap";
import { getCompletamentoQuestionariPerUtente } from "app/actions";
import { get, map } from "lodash";
import { Link } from "react-router-dom";
import StatoQuestionario from "app/elements/StatoQuestionario.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class ListaQuestionariUtente extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    listaQuestionari: PropTypes.array.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <AsyncData
        loaderAction={this.props.getCompletamentoQuestionariPerUtente}
        loaderArgs={[get(this.props, "user.id")]}
      >
        {({ loading, loadingError, data }) => {
          console.log({ loading, loadingError, data });
          if (loading) {
            return <Loading />;
          }
          if (loadingError) {
            return <Alert color="danger">{loadingError.message}</Alert>;
          }
          return (
            <Table responsive={true} bordered={true}>
              <thead>
                <tr>
                  <th style={{ width: 150 }}></th>
                  <th>{t("questionario_label")} </th>
                  <th style={{ width: 100 }}></th>
                </tr>
              </thead>
              <tbody>
                {map(get(this.props, "listaQuestionari"), (row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>
                      <Button
                        className=""
                        color="primary"
                        size="sm"
                        outline={true}
                        tag={Link}
                        to={
                          "/user/" +
                          get(this.props, "user.id") +
                          "/questionario/" +
                          get(row, "type")
                        }
                      >
                        {t("apri_questionario_label")}
                      </Button>
                    </td>
                    <td>{row.name}</td>
                    <td>
                      <StatoQuestionario
                        user={get(this.props, "user")}
                        questionariCompletamento={data}
                        questionarioType={get(row, "type")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          );
        }}
      </AsyncData>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  getCompletamentoQuestionariPerUtente: getCompletamentoQuestionariPerUtente
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListaQuestionariUtente)
);
