import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import { get, map } from "lodash";
import fpSet from "lodash/fp/set";
import {
  getCodiciCentroPossibilePerUser,
  centroHaEsmActigrafo
} from "common/utils/codiceCentro.js";
import DatetimePicker from "app/elements/DatetimePicker";
import NumberInput from "app/elements/NumberInput";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AbstractPartecipanteForm from "./AbstractPartecipanteForm";
import { HA_ESM } from "common/datiPortale";

class ControlloForm extends Component {
  static propTypes = {
    paziente: PropTypes.object.isRequired,
    updatePaziente: PropTypes.func,
    pazienteErrors: PropTypes.object,
    isCreate: PropTypes.bool,
    isEditable: PropTypes.bool
  };

  static defaultProps = {
    isCreate: false,
    isEditable: true
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      t,
      isEditable,
      isCreate,
      paziente,
      updatePaziente,
      pazienteErrors
    } = this.props;

    return (
      <AbstractPartecipanteForm
        partecipante={paziente}
        updatePartecipante={updatePaziente}
        partecipanteErrors={pazienteErrors}
        isCreate={isCreate}
        isEditable={isEditable}
        showTipoPaziente={false}
        showTipoControllo={true}
        showOperatoreDiRiferimento={false}
        showPazienteDiRiferimento={true}
        showScolarita
        showRitirato
        showEsmActigrafoApp={HA_ESM}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ControlloForm)
);
