export const codiciCentri = [
  {
    codiceCentro: "BS01",
    nomeCentro: "IRCCS Fatebenefratelli Brescia",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "LE01",
    nomeCentro: "IRCCS Eugenio Medea la Nostra Famiglia",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "PA01",
    nomeCentro: "Azienda Ospedale Università Paolo Giaccone",
    haControlli: true,
    haEsmActigrafo: true
  }
];
