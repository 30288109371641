import {
  __tipiPazienti,
  __tipiPazientiInfo,
  __tipiControlli,
  __tipiControlliInfo
} from "./datiPortale";

export const tipiPazienti = __tipiPazienti;
export const tipiPazientiInfo = __tipiPazientiInfo;

export const tipiControlli = __tipiControlli;
export const tipiControlliInfo = __tipiControlliInfo;

export function checkTipoUtenteHasOperatoreDiRiferimento(tipo) {
  return tipiPazientiInfo[tipo]
    ? tipiPazientiInfo[tipo].hasOperatoreDiRiferimento
    : false;
}

export function checkTipoUtenteHasPazienteDiRiferimento(tipo) {
  return tipiControlliInfo[tipo]
    ? tipiControlliInfo[tipo].hasPazienteDiRiferimento
    : false;
}
