import React from "react";

export default {
  AbstractDettaglioPartecipante: {
    index: React.lazy(() => import("./AbstractDettaglioPartecipante.js"))
  },
  AbstractListaPartecipanti: {
    index: React.lazy(() => import("./AbstractListaPartecipanti.js"))
  },
  AbstractPartecipanteForm: {
    index: React.lazy(() => import("./AbstractPartecipanteForm.js"))
  },
  ControlloForm: {
    index: React.lazy(() => import("./ControlloForm.js"))
  },
  DettaglioControllo: {
    index: React.lazy(() => import("./DettaglioControllo.js"))
  },
  DettaglioOperatore: {
    index: React.lazy(() => import("./DettaglioOperatore.js"))
  },
  DettaglioPaziente: {
    index: React.lazy(() => import("./DettaglioPaziente.js"))
  },
  EsportaDati: {
    index: React.lazy(() => import("./EsportaDati.js"))
  },
  Header: {
    index: React.lazy(() => import("./Header.js"))
  },
  ListaControlli: {
    index: React.lazy(() => import("./ListaControlli.js"))
  },
  ListaOperatori: {
    index: React.lazy(() => import("./ListaOperatori.js"))
  },
  ListaPazienti: {
    index: React.lazy(() => import("./ListaPazienti.js"))
  },
  ListaQuestionariUtente: {
    index: React.lazy(() => import("./ListaQuestionariUtente.js"))
  },
  Login: {
    index: React.lazy(() => import("./Login.js"))
  },
  Main: {
    index: React.lazy(() => import("./Main.js"))
  },
  Obiettivo2: {
    index: React.lazy(() => import("./Obiettivo2.js"))
  },
  OperatoreForm: {
    index: React.lazy(() => import("./OperatoreForm.js"))
  },
  PazienteForm: {
    index: React.lazy(() => import("./PazienteForm.js"))
  },
  Questionario: {
    index: React.lazy(() => import("./Questionario.js"))
  },
  QuestionarioForm: {
    index: React.lazy(() => import("./QuestionarioForm.js"))
  },
  Sidebar: {
    index: React.lazy(() => import("./Sidebar.js"))
  },
  Statistiche: {
    index: React.lazy(() => import("./Statistiche.js"))
  },
  Training: {
    index: React.lazy(() => import("./Training.js"))
  },
  mobile: {
    Header: {
      index: React.lazy(() => import("./mobile/Header.js"))
    },
    Login: {
      index: React.lazy(() => import("./mobile/Login.js"))
    },
    Main: {
      index: React.lazy(() => import("./mobile/Main.js"))
    },
    MobileUI: {
      index: React.lazy(() => import("./mobile/MobileUI.js"))
    }
  }
};
