import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import getNotificaDovutaOra from "app/utils/getNotificaDovutaOra.js";
import NotificaContainer from "./NotificaContainer";
import { mobileGetUser, inviaNotificaConRisposte } from "app/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const CHECK_INTERVAL = 10 * 1000;

class MainLoggedPage extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  state = {
    notifica: null,
    started: false
  };

  componentDidMount() {
    this.mounted = true;
    this.checkWhatToDo();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this._checkTimeout);
  }

  getUser() {
    return this.state.user || this.props.user;
  }

  getUpdatedUser() {
    const currentUser = this.getUser();
    if (currentUser && currentUser.id === "DEMO") {
      return;
    }

    return new Promise(async resolve => {
      let user = null;

      const action = await this.props.mobileGetUser();
      const result = action.response;

      user = result && result.data;

      if (user) {
        this.setState(
          {
            user
          },
          () => {
            resolve();
          }
        );
      } else {
        resolve();
      }
    });
  }

  checkWhatToDo = async () => {
    await this._checkWhatToDo();
    if (this.mounted) {
      this._checkTimeout = setTimeout(this.checkWhatToDo, CHECK_INTERVAL);
    }
  };

  _checkWhatToDo = async () => {
    if (!this.mounted) {
      return;
    }

    try {
      //TODO: ricarica l'user dal server per vedere se ci sono cambiamenti
      // + potrebbe essere stato de loggato
      // + potrebbero esserci nuove notifiche
      await this.getUpdatedUser();
    } catch (err) {
      console.log(err);
    }

    if (!this.mounted) {
      return;
    }

    const user = this.getUser();

    if (this.state.started) {
      return;
    }

    const notifica = getNotificaDovutaOra(user);

    if (notifica) {
      this.setState({
        notifica: notifica,
        started: true
      });
    }
  };

  setRisposte = risposte => {
    const newNotifica = {
      ...this.state.notifica,
      risposte: {
        ...(this.state.notifica.risposte || {}),
        ...risposte
      }
    };
    this.setState({
      notifica: newNotifica
    });

    if (!newNotifica.dataAperturaNotifica) {
      newNotifica.dataAperturaNotifica = new Date().toISOString();
    }

    this.inviaNotifica(newNotifica);
  };

  inviaNotifica(newNotifica) {
    const user = this.getUser();
    if (user && user.id === "DEMO") {
      return;
    }

    this._notificaDaInviare = newNotifica;
    if (this._inviando) {
      return;
    }
    this._invia();
  }

  async _invia() {
    this._inviando = true;
    const notifica = this._notificaDaInviare;
    this._notificaDaInviare = null;

    try {
      const action = await this.props.inviaNotificaConRisposte(notifica);
      const result = action.response;
      if (!result || !result.data || !result.data.salvato) {
        console.log("Non salvato!");
      }
    } catch (err) {
      console.log(err);
    }

    this._inviando = false;

    if (this._notificaDaInviare) {
      await this._invia();
    }
  }

  render() {
    const { t } = this.props;

    const user = this.getUser();
    const { notifica, started } = this.state;

    if (started) {
      return (
        <NotificaContainer
          user={user}
          notifica={notifica}
          setRisposte={this.setRisposte}
        />
      );
    }

    return (
      <>
        <Row>
          <Col>
            <h4>
              {t("mobile:partecipante_label")}: {user.codiceUtente}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{t("mobile:non_ci_sono_domande_a_cui_rispondere")}.</p>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  mobileGetUser: mobileGetUser,
  inviaNotificaConRisposte: inviaNotificaConRisposte
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainLoggedPage)
);
