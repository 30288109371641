import { PROJECT_ID } from "../datiPortale";

import * as diapason from "./domadeQuestionarioMobile_DIAPASON";
import * as bipcom from "./domadeQuestionarioMobile_BIPCOM";
import * as riskmet from "./domadeQuestionarioMobile_RISKMET";

let obj;

if (PROJECT_ID == "diapason") {
  obj = diapason;
} else if (PROJECT_ID == "bipcom") {
  obj = bipcom;
} else if (PROJECT_ID == "riskmet") {
  obj = riskmet;
} else {
  // default, ... no app mobile?
  obj = bipcom;
}

const { sezioni, getPrimaDomandaNonRisposta, checkCompletato } = obj;

export { sezioni, getPrimaDomandaNonRisposta, checkCompletato };
