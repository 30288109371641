import React from "react";
import { Component } from "react";
import {
  getPazienti,
  getCompletamentoQuestionariPerUtente,
  creaPaziente
} from "app/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AbstractListaPartecipanti from "./AbstractListaPartecipanti";

class ListaPazienti extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AbstractListaPartecipanti
        getPartecipanti={this.props.getPazienti}
        isPazienti
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  getPazienti: getPazienti,
  getCompletamentoQuestionariPerUtente: getCompletamentoQuestionariPerUtente,
  creaPaziente: creaPaziente
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListaPazienti)
);
