import { find, some } from "lodash";

/*
default value: en

labelI18N: {
  it: "",
  es: "",
  fr: "",
  de: "",
},

preLabelI18N: {
  it: "",
  es: "",
  fr: "",
  de: "",
},
*/

export const sezioni = [
  // DOMANDA INIZIALE
  {
    domande: [
      {
        key: "tipo-utente",
        label: "0) Che tipo di utente sei?",
        type: "single-choice",
        options: [
          {
            label: "Genitore",
            value: "genitore"
          },
          {
            label: "Adulto o adolescente",
            value: "adulto-adolescente"
          }
        ]
      }
    ]
  },

  // SECTION  1
  {
    domande: [
      {
        key: "activities",
        label: "Cosa stai facendo in questo momento?",
        label_genitore: "Cosa sta facendo suo figlio in questo momento?",
        type: "multi-choice",
        options: [
          {
            label: "Sto per dormire",
            label_genitore: "Sta per dormire",
            value: 1
          },
          {
            label: "Sono ammalato a letto",
            label_genitore: "È ammalato a letto",
            value: 2
          },
          {
            label: "Mangiando/bevendo/ facendo merenda",
            value: 3
          },
          {
            label: "Cura personale (mi sto lavando, vestendo, ...)",
            label_genitore: "Cura personale (si sta lavando, vestendo, ...)",
            value: 4
          },
          {
            label: "Lavorando o facendo tirocinio ",
            value: 5
          },
          {
            label: "Studiando/Frequentando le lezioni ",
            value: 6
          },
          {
            label: "Pulendo/Cucinando/Sistemando la casa/Facendo compere",
            value: 7
          },
          {
            label:
              "Mi sto prendendo cura di qualcuno (persona, animale, pianta)",
            label_genitore:
              "Mi sto prendendo cura di qualcuno (persona, animale, pianta)",
            value: 8
          },
          {
            label: "Volontariato",
            value: 9
          },
          {
            label:
              "Attività di svago (stare in compagnia, giocare, chiacchierare, leggere, andare al cinema, suonare uno strumento etc.)",
            value: 10
          },
          {
            label: "Pensando, riposando, non fare nulla (senza dormire)",
            value: 11
          },
          {
            label: "Facendo sport, attività sportiva",
            value: 12
          },
          {
            label:
              "Sono in giro (a piedi o in bicicletta, macchina, sui mezzi pubblici)",
            label_genitore:
              "È in giro (a piedi o in bicicletta, macchina, sui mezzi pubblici)",
            value: 13
          },
          {
            label:
              "Guardando la TV / ascoltando la radio, la musica o un podcast",
            value: 14
          },
          {
            label:
              "Sto partercipando ad attività religiose (sto andando in chiesa, sto pregando)",
            label_genitore:
              "Sta partercipando ad attività religiose (sta andando in chiesa, sta pregando)",
            value: 15
          }
        ]
      },
      {
        key: "interpersonal_relationships",
        label: "Con chi sei adesso?",
        label_genitore: "Con chi si trova suo figlio adesso?",
        type: "single-choice",
        options: [
          {
            label: "Solo",
            value: 16
          },
          {
            label: "Con altre persone",
            value: 17
          }
        ]
      }
    ]
  },

  // SECTION  2
  {
    domande: [
      {
        key: "location",
        label: "Dove ti trovi in questo momento?",
        label_genitore: "Dove si trova suo figlio in questo momento?",
        type: "single-choice",
        options: [
          {
            label: "A casa",
            value: 1
          },
          {
            label: "Al lavoro/scuola/università",
            value: 2
          },
          {
            label: "In un negozio",
            value: 3
          },
          {
            label:
              "Mi sto muovendo da un luogo ad un altro luogo (con trasporto privato/ con trasporto pubblico come pullman, metropolitana, treno etc)",
            label_genitore:
              "Si sta muovendo da un luogo ad un altro luogo (con trasporto privato/ con trasporto pubblico come pullman, metropolitana, treno etc)",
            value: 4
          },
          {
            label: "In un luogo di svago (bar, cinema, palestra)",
            value: 5
          },
          {
            label: "In un luogo di cura ( ospedale, ambulatorio, clinica, etc)",
            value: 6
          }
        ]
      }
    ]
  },

  // SECTION  3
  {
    randomSort: true,
    domande: [
      // domanda iniziale per capire se chiedere le restanti...
      {
        noSort: true,
        key: "sei_con_tuo_figlio",
        condition: {
          key: "tipo-utente",
          value: "genitore"
        },
        label: "Suo figlio in questo momento è con lei?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ]
      },

      ...[
        {
          label: "Contento",
          key: "emotion_content"
        },
        {
          label: "Abbattuto",
          key: "emotion_down"
        },
        {
          label: "Irritatato",
          key: "emotion_irritated"
        },
        {
          label: "Allegro",
          key: "emotion_cheerful"
        },
        {
          label: "Irrequieto",

          key: "emotion_restless"
        },
        {
          label: "Esausto ",
          key: "emotion_exhausted"
        },
        {
          label: "Pieno di energie",
          key: "emotion_full_of_energy"
        }
      ].map(stato => ({
        preLabel: "In questo momento, quanto ti senti",
        preLabel_genitore: "In questo momento, come si sente suo figlio",
        key: stato.key,
        label: stato.label,
        type: "barra0-100",
        condition: {
          key: "tipo-utente",
          value: "adulto-adolescente"
        },
        condition2: {
          or: true,
          key: "sei_con_tuo_figlio",
          value: 1
        }
      }))
    ]
  },

  // SECTION  3
  {
    sectionFilterKey: "domandaGiornataSpeciale_typicalDay",
    domande: [
      {
        key: "typical_day",
        label:
          "Pensando a oggi, diresti che è stata una giornata tipica per te?",
        label_genitore:
          "Pensando a oggi, direbbe che è stata una giornata tipica per suo figlio?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ]
      },
      {
        key: "not_typical_day_why",
        label: "Descrivi la motivazione",
        type: "text",
        condition: {
          key: "typical_day",
          value: 2
        }
      },

      // solo per filgio
      {
        key: "caffeine",
        label: "Hai bevuto caffè oggi?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ],
        condition: {
          key: "tipo-utente",
          value: "adulto-adolescente"
        }
      },
      {
        key: "caffeine_quantity",
        label: "Quante tazze?",
        type: "text",
        condition: {
          key: "caffeine",
          value: 1
        }
      },
      {
        key: "nicotine",
        label: "Hai fumato sigarette oggi?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ],
        condition: {
          key: "tipo-utente",
          value: "adulto-adolescente"
        }
      },
      {
        key: "alcohol",
        label: "Hai bevuto bevande alcoliche oggi?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ],
        condition: {
          key: "tipo-utente",
          value: "adulto-adolescente"
        }
      }
    ]
  },

  // SECTION  4
  {
    sectionFilterKey: "domandaGiornataSpeciale_sleep",
    domande: [
      {
        key: "sleep",
        label: "Hai dormito bene?",
        label_genitore: "Suo figlio ha dormito bene?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ]
      },
      {
        key: "sleep_hours",
        label: "Quante ore hai dormito questa notte?",
        label_genitore: "Quante ore ha dormito questa notte?",
        type: "text"
      },
      {
        key: "time_before_sleep",
        label: "Quanto tempo hai impiegato ad addormentarsi? (in minuti)",
        label_genitore:
          "Quanto tempo ha impiegato ad addormentarsi? (in minuti)",
        type: "text"
      },
      {
        key: "wake_up_night",
        label: "Quante volte ti sei svegliato durante la notte?",
        label_genitore: "Quante volte si è svegliato durante la notte?",
        type: "text"
      }
    ]
  },

  // SECTION  5
  {
    sectionFilterKey: "domandaGiornataSpeciale_after_eat",
    domande: [
      {
        key: "eaten",
        label: "Dall’ultima volta che ha aperto questa app, ha mangiato?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ]
      },
      {
        key: "eaten_feel",
        label: "Come si è sentito dopo il pasto?",
        type: "single-choice",
        options: [
          {
            label: "Avevo ancora fame",
            value: 1
          },
          {
            label: "Ero sazio",
            value: 2
          },
          {
            label: "Ero spiacevolmente sazio ",
            value: 3
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_overeat",
        label: "Pensa di aver mangiato più del dovuto?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_loss_of_control",
        label: "Ha sperimentato una perdita di controllo durante il pasto?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_guilty",
        label: "Si è sentito in colpa dopo questo pasto?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ],
        condition: {
          key: "eaten",
          value: 1
        }
      },
      {
        key: "eaten_tried_something",
        label:
          "Ha provato a fare qualcosa per eliminare le calorie assunte con questo pasto?",
        type: "single-choice",
        options: [
          {
            label: "Sì",
            value: 1
          },
          {
            label: "No",
            value: 2
          }
        ],
        condition: {
          key: "eaten_guilty",
          value: 1
        }
      },
      {
        key: "eaten_tried_something_list",
        label: "Che cosa ha fatto o che cosa ha provato a fare?",
        condition: {
          key: "eaten_tried_something",
          value: 1
        },
        type: "multi-choice",
        options: [
          {
            label: "Vomitare",
            value: 1
          },
          {
            label: "Digiunare o	mangiare meno",
            value: 2
          },
          {
            label: "Assumere lassativi",
            value: 3
          },
          {
            label: "Attività fisica in grandi quantità",
            value: 4
          }
        ]
      }
    ]
  }
];

export function getPrimaDomandaNonRisposta(notifica, sezioni, risposte, user) {
  let domandaAttuale = null;
  find(sezioni, s => {
    // rimuove domande speciali
    if (s.sectionFilterKey && !notifica[s.sectionFilterKey]) {
      return false;
    }
    return find(s.domande, d => {
      if (risposte[d.key] == null) {
        if (d.condition) {
          const rispostaAltraDomanda = risposte[d.condition.key];
          if (rispostaAltraDomanda != d.condition.value) {
            if (d.condition2 && d.condition2.or) {
              const rispostaAltraDomanda2 = risposte[d.condition2.key];
              if (rispostaAltraDomanda2 != d.condition2.value) {
                return false;
              }
            } else {
              return false;
            }
          }
        }
        domandaAttuale = d;
        return true;
      }
      return false;
    });
  });

  if (domandaAttuale) {
    const isGenitore = risposte["tipo-utente"] === "genitore";
    if (isGenitore) {
      domandaAttuale = { ...domandaAttuale };
      if (domandaAttuale.label_genitore) {
        domandaAttuale.label = domandaAttuale.label_genitore;
      }
      if (domandaAttuale.preLabel_genitore) {
        domandaAttuale.preLabel = domandaAttuale.preLabel_genitore;
      }
      if (domandaAttuale.options) {
        domandaAttuale.options = domandaAttuale.options.map(x => {
          if (x.label_genitore) {
            x = { ...x };
            x.label = x.label_genitore;
          }
          return x;
        });
      }
    }
  }

  return domandaAttuale;
}

export function checkCompletato(notifica, user) {
  const risposte = notifica.risposte || {};
  let domandaAttuale = getPrimaDomandaNonRisposta(
    notifica,
    sezioni,
    risposte,
    user
  );
  if (!domandaAttuale && !notifica.dataCompilazione) {
    notifica.dataCompilazione = new Date().toISOString();
  }
}

function getKeyDayPrefix(key) {
  return key.replace(/_n_[0-9]+/, "");
}

function isSameDay(key1, key2) {
  return getKeyDayPrefix(key1) === getKeyDayPrefix(key2);
}
