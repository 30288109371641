import React from "react";
import { Component } from "react";
import { get } from "lodash";
import { updatePasswordOperatore } from "app/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AbstractPartecipanteForm from "./AbstractPartecipanteForm";

class OperatoreForm extends Component {
  static propTypes = {
    operatore: PropTypes.object.isRequired,
    updateOperatore: PropTypes.func,
    operatoreErrors: PropTypes.object,
    isCreate: PropTypes.bool,
    isEditable: PropTypes.bool
  };

  static defaultProps = {
    isCreate: false,
    isEditable: true
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      t,
      isEditable,
      isCreate,
      operatore,
      updateOperatore,
      operatoreErrors
    } = this.props;
    return (
      <AbstractPartecipanteForm
        partecipante={operatore}
        updatePartecipante={updateOperatore}
        partecipanteErrors={operatoreErrors}
        isCreate={isCreate}
        isEditable={isEditable}
        showTipoPaziente={false}
        showTipoControllo={false}
        showOperatoreDiRiferimento={false}
        showPazienteDiRiferimento={false}
        showScolarita={false}
        showRitirato={false}
        showEsmActigrafoApp={false}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  updatePasswordOperatore: updatePasswordOperatore
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OperatoreForm)
);
