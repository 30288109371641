import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import DebugInfo from "./elements/DebugInfo";
import { routes } from "common/routes";
import LayoutWrapper from "./pages/LayoutWrapper";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
class App extends Component {
  render() {
    const DebugInfoOrNull = null; // process.env.NODE_ENV !== 'production' ? DebugInfo : null

    return (
      <>
        {DebugInfoOrNull && <DebugInfoOrNull />}
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <Switch>
              {routes.map(function(route, index) {
                const routeRender = props => (
                  <LayoutWrapper route={route} {...props} />
                );

                if (!route.path) {
                  return <Route key={index} render={routeRender} />;
                }
                return (
                  <Route
                    key={index}
                    exact={true}
                    path={route.path}
                    render={routeRender}
                  />
                );
              })}
            </Switch>
          </BrowserRouter>
        </I18nextProvider>
      </>
    );
  }
}

let _App = App;

if (process.env.NODE_ENV === "development") {
  const hot = require("react-hot-loader/root").hot;
  _App = hot(_App);
}

export default _App;
