// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "CALL_API";

import baseUrl from "../utils/baseUrl";

import i18n from "../i18n";

// Fetches an API response
const callApi = (endpoint, method, body, abortController) => {
  let headers = {
    Accept: "application/json"
  };
  if (body && body instanceof FormData) {
    body = body;
  } else if (body) {
    body = JSON.stringify(body);
    headers["Content-Type"] = "application/json";
  }
  endpoint = baseUrl + endpoint;
  return fetch(endpoint, {
    method,
    body,
    headers: headers,
    credentials: "include",
    signal: abortController && abortController.signal
  }).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { endpoint, method } = callAPI;
  const { body, types, abortController } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  method = method || "GET";

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }));

  return callApi(endpoint, method, body, abortController).then(
    response =>
      next(
        actionWith({
          type: successType,
          callApiSuccess: true,
          response
        })
      ),
    error =>
      next(
        actionWith({
          type: failureType,
          callApiSuccess: false,
          response:
            error instanceof Error && error.message === "Failed to fetch"
              ? { message: "Connection error. Try again later." }
              : i18nError(error)
        })
      )
  );
};

function i18nError(error) {
  if (error && typeof error.message === "string") {
    error.message = i18n.t("serverErrors:" + error.message, {
      defaultValue: error.message,
      keySeparator: "#####.#####"
    });
  }
  if (error && error.errors) {
    const keys = Object.keys(error.errors);
    keys.forEach(function(key) {
      const v = error.errors[key];
      if (typeof v === "string") {
        error.errors[key] = i18n.t("serverErrors:" + v, {
          defaultValue: v,
          keySeparator: "#####.#####"
        });
      }
    });
  }
  return error;
}
