import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Button,
  Table
} from "reactstrap";
import { get, map } from "lodash";
import { Link } from "react-router-dom";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { exportDataListaUtentiConDatiActigrafo } from "app/actions";
import LinkDownloadFileActigrafo from "app/elements/LinkDownloadFileActigrafo.js";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { HA_ESM } from "common/datiPortale";
import { PROJECT_ID } from "common/datiPortale";

class EsportaDati extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>{t("esporta_dati_label")}</CardHeader>

            <CardBody>
              {get(this.props, "user.userType") == "Operatore" ? (
                get(this.props, "user.adminCentro") ? (
                  <Row>
                    <Col sm={12}>
                      <Alert color="info">{t("esporta_dati_info_1")}</Alert>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={12}>
                      <Alert color="info">{t("esporta_dati_info_2")}</Alert>
                    </Col>
                  </Row>
                )
              ) : null}

              <Row>
                <Col sm={12}>
                  <Button
                    color="primary"
                    size="lg"
                    tag={Link}
                    to={"/api/export/data"}
                    target={"_blank"}
                  >
                    {PROJECT_ID == "bipcom-mrat"
                      ? "Download MRAT Database"
                      : t("scarica_tutti_i_questionari_label")}
                  </Button>

                  <div className="text-muted">
                    {PROJECT_ID == "bipcom-mrat"
                      ? ""
                      : t("scarica_tutti_dati_raccolti_label")}
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                {HA_ESM && (
                  <Col sm={12}>
                    <Button
                      color="primary"
                      size="lg"
                      tag={Link}
                      to={"/api/export/dataESM"}
                      target={"_blank"}
                    >
                      {t("scarica_dati_esm_label")}
                    </Button>

                    <div className="text-muted">
                      {t("scarica_dati_esm_label_2")}
                    </div>
                  </Col>
                )}
              </Row>

              {/* <Row style={{ marginTop: 30 }}>
                <Col sm={12}>
                  <Button
                    color="primary"
                    size="lg"
                    tag={Link}
                    to={"/api/export/dataDiari"}
                    target={"_blank"}
                  >
                    {t("scarica_dati_diari_label")}
                  </Button>

                  <div className="text-muted">
                    {t("scarica_dati_diari_label_2")}
                  </div>
                </Col>
              </Row> */}
            </CardBody>
          </Card>
        </Col>

        <Col sm={12}>
          <Card>
            <CardHeader>{t("dati_actigrafo_label")} </CardHeader>

            <CardBody>
              <Row>
                <Col sm={12}>
                  <AsyncData
                    loaderAction={
                      this.props.exportDataListaUtentiConDatiActigrafo
                    }
                  >
                    {({ loading, loadingError, data }) => {
                      console.log({ loading, loadingError, data });
                      if (loading) {
                        return <Loading />;
                      }
                      if (loadingError) {
                        return (
                          <Alert color="danger">{loadingError.message}</Alert>
                        );
                      }
                      return (
                        <Table responsive={true} bordered={true} striped={true}>
                          <thead>
                            <tr>
                              <th style={{}}>
                                {t("dati_actigrafo_table_col_codice_soggetto")}{" "}
                              </th>
                              <th style={{}}>
                                {t("dati_actigrafo_table_col_tipo_soggetto")}{" "}
                              </th>
                              <th style={{}}>
                                {t("dati_actigrafo_table_col_centro")}{" "}
                              </th>
                              <th style={{}}>
                                {t("dati_actigrafo_table_col_file")}{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(data, (row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td style={{}}>{row.codiceUtente}</td>
                                <td style={{}}>{row.userType}</td>
                                <td style={{}}>{row.codiceCentro}</td>
                                <td style={{}}>
                                  <LinkDownloadFileActigrafo user={row} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      );
                    }}
                  </AsyncData>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  exportDataListaUtentiConDatiActigrafo: exportDataListaUtentiConDatiActigrafo
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EsportaDati)
);
