import React from "react";
import { Row, Col, Button, Input } from "reactstrap";
import {
  sezioni,
  getPrimaDomandaNonRisposta
} from "../../common/utils/domadeQuestionarioMobile";
import { find, shuffle } from "lodash";
import Barra0_100 from "./Barra0_100";
import { withTranslation } from "react-i18next";

if (
  window &&
  window.location &&
  window.location.pathname &&
  window.location.pathname.startsWith("/mobile-app/ui/")
) {
  document.body.classList.add("bipcom-mobile-ui");
}

class NotificaContainer extends React.Component {
  state = {
    risposte: {}
  };

  isSelected(domandaAttuale, option) {
    const risposte = this.state.risposte || {};
    if (domandaAttuale.type === "multi-choice") {
      const a = (
        (typeof risposte[domandaAttuale.key] === "number"
          ? "" + risposte[domandaAttuale.key]
          : risposte[domandaAttuale.key]) || ""
      ).split(",");
      return a.indexOf("" + option.value) !== -1;
    }
    return risposte[domandaAttuale.key] === option.value;
  }

  toggle(domandaAttuale, option) {
    const risposte = this.state.risposte || {};
    let newValue;
    if (domandaAttuale.type === "multi-choice") {
      const a = (
        (typeof risposte[domandaAttuale.key] === "number"
          ? "" + risposte[domandaAttuale.key]
          : risposte[domandaAttuale.key]) || ""
      )
        .split(",")
        .filter(x => x);
      if (a.indexOf("" + option.value) === -1) {
        a.push("" + option.value);
      } else {
        a.splice(a.indexOf("" + option.value), 1);
      }
      newValue = a.length > 0 ? a.join(",") : null;
    } else {
      const wasSelected = this.isSelected(domandaAttuale, option);
      newValue = wasSelected ? null : option.value;
    }

    const newRisposte = {
      ...risposte,
      [domandaAttuale.key]: newValue
    };

    this.setState({
      risposte: newRisposte
    });
  }

  getDomandaValue(domandaAttuale) {
    const risposte = this.state.risposte || {};
    return risposte[domandaAttuale.key];
  }

  setDomandaValue(domandaAttuale, v) {
    const risposte = this.state.risposte || {};
    const newRisposte = {
      ...risposte,
      [domandaAttuale.key]: v
    };
    this.setState({
      risposte: newRisposte
    });
  }

  onNext = () => {
    const risposte = this.state.risposte || {};
    this.props.setRisposte(risposte);
  };

  onInputCreated = inputEl => {
    setTimeout(() => {
      inputEl && inputEl.focus();
    }, 400);
  };

  _getLabelFromI18nObj(obj) {
    const lng = this.props.i18n && this.props.i18n.resolvedLanguage;
    return (obj && obj[lng]) || null;
  }

  getLabel(obj) {
    return this._getLabelFromI18nObj(obj.labelI18N) || obj.label;
  }

  getPreLabel(obj) {
    return this._getLabelFromI18nObj(obj.preLabelI18N) || obj.preLabel;
  }

  renderDomanda(domandaAttuale) {
    return (
      <>
        <div className="mobile-notifica-domanda">
          {domandaAttuale.preLabel && (
            <div className="pre-label">{this.getPreLabel(domandaAttuale)}</div>
          )}
          <div className="domanda-label">{this.getLabel(domandaAttuale)}</div>
          {domandaAttuale.options && (
            <table>
              <tbody>
                {domandaAttuale.options.map((o, i) => {
                  let selected = this.isSelected(domandaAttuale, o);
                  let onClick = e => {
                    e.preventDefault();
                    this.toggle(domandaAttuale, o);
                    const target = e.target;
                    target.blur();
                  };
                  return (
                    <tr key={i}>
                      <td>
                        <Button
                          color="primary"
                          outline={!selected}
                          onClick={onClick}
                        >
                          {this.getLabel(o)}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {domandaAttuale.type === "barra0-100" && (
            <Barra0_100
              value={this.getDomandaValue(domandaAttuale)}
              onChange={v => {
                this.setDomandaValue(domandaAttuale, v);
              }}
            />
          )}
          {domandaAttuale.type === "text" && (
            <Input
              innerRef={this.onInputCreated}
              className="text-value"
              autoFocus={true}
              placeholder=""
              value={this.getDomandaValue(domandaAttuale) || ""}
              onChange={e => {
                const v = e.target.value || null;
                this.setDomandaValue(domandaAttuale, v);
              }}
            />
          )}
        </div>
      </>
    );
  }

  renderNoMoreDomande() {
    const { t } = this.props;
    return (
      <div>
        <h4>{t("mobile:grazie_label")}!</h4>
        <p>{t("mobile:grazie_label_2")}</p>
        <p style={{ marginTop: 50 }}>{t("mobile:puoi_chiudere_app")}</p>
      </div>
    );
  }

  renderBottomBar(domandaAttuale) {
    const { t } = this.props;
    const risposte = this.state.risposte || {};
    const isOk = risposte[domandaAttuale.key] != null;
    return (
      <div className="mobile-domanda-bottom-bar">
        <Button
          color="success"
          outline={!isOk}
          disabled={!isOk}
          onClick={this.onNext}
        >
          {t("mobile:avanti")}
        </Button>
      </div>
    );
  }

  getSezioni = () => {
    if (!this._randomSezioni) {
      this._randomSezioni = sezioni.map(s => {
        if (s.randomSort) {
          const domandeNoSort = [];
          let domandeToSort = [];

          s.domande.forEach((d, i) => {
            if (d.noSort) {
              domandeNoSort.push([i, d]);
            } else {
              domandeToSort.push(d);
            }
          });

          domandeToSort = shuffle(domandeToSort);
          const sortedDomande = [...domandeToSort];
          domandeNoSort.forEach(([i, d]) => {
            sortedDomande.splice(i, 0, d);
          });

          return {
            ...s,
            domande: sortedDomande
          };
        } else {
          return s;
        }
      });
    }
    return this._randomSezioni;
  };

  renderContent() {
    const { user, notifica } = this.props;
    const risposte = notifica.risposte || {};

    let domandaAttuale = getPrimaDomandaNonRisposta(
      notifica,
      this.getSezioni(),
      risposte,
      user
    );

    return (
      <div className="mobile-notifica-container">
        {domandaAttuale
          ? this.renderDomanda(domandaAttuale)
          : this.renderNoMoreDomande()}
        {domandaAttuale ? this.renderBottomBar(domandaAttuale) : null}
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}

export default withTranslation()(NotificaContainer);
