import { getQuestionarioByKey } from "./questionari";
import { each, every, some } from "lodash";
import moment from "moment";

export function checkDomandaIf(domanda, risposte, questionarioData) {
  if (domanda.if) {
    const ifs = Array.isArray(domanda.if) ? domanda.if : [domanda.if];
    for (let index = 0; index < ifs.length; index++) {
      const _if = ifs[index];

      const comparator = _if.comparator;
      //TODO: check comparator

      let ifRispostaOk;
      // USER_INFO_
      if (_if.key.startsWith("USER_INFO_")) {
        const userKey = _if.key.substring("USER_INFO_".length);
        const user = questionarioData.user;
        const userValue = user[userKey];
        ifRispostaOk = userValue === _if.value;
      } else {
        if (risposte) {
          if (_if.isMulti) {
            ifRispostaOk = some(
              risposte[_if.key] &&
                (typeof risposte[_if.key] === "number"
                  ? "" + risposte[_if.key]
                  : risposte[_if.key]
                ).split(","),
              v => v == _if.value
            );
          } else {
            ifRispostaOk = risposte[_if.key] === _if.value;
          }
        }
      }

      if (ifRispostaOk) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export function forEachDomanda(questionarioData, callback) {
  const questionarioObj = getQuestionarioByKey(
    questionarioData.questionarioKey
  );
  const domande = questionarioObj.domande;
  const risposte = questionarioData.risposte;

  function onDomanda(domanda) {
    if (!checkDomandaIf(domanda, risposte, questionarioData)) {
      return;
    }
    switch (domanda.type) {
      case "group-table":
        domanda.rows.forEach(domandaSub => {
          onDomanda(domandaSub);
        });
        break;

      default:
        callback(domanda);
    }
  }

  domande.forEach(domanda => {
    onDomanda(domanda);
  });
}

function getRispostePossibili(domanda) {
  switch (domanda.type) {
    case "description":
    case "group-table-row-descrizione":
      return false;

    case "free-text":
    case "date":
      return true;

    case "number":
      return {
        domandaNumber: true,
        from:
          domanda.range && typeof domanda.range.from === "number"
            ? domanda.range.from
            : -Infinity,
        to:
          domanda.range && typeof domanda.range.to === "number"
            ? domanda.range.to
            : Infinity
      };

    case "group-table":
      return [];

    default:
      return domanda.values.map(obj => obj.value);
  }
}

export const domandeFacoltative = {
  PSICH_6_diagnosiprincipale_comorb_quale: true,
  PSICH_6__diagnosi_comorb_quale: true,
  PSICH_6__diagnosi_comorb_quale2: true
};
export const questionariFacoltativi = {
  "WAI-T_2": true,
  "WAI-T_3": true
};

function getDomandeKeysWithValues(questionarioData) {
  const keysMap = {};

  forEachDomanda(questionarioData, domanda => {
    if (domandeFacoltative[domanda.key]) {
      return;
    }
    if (domanda.key) {
      keysMap[domanda.key] = {
        rispostePossibili: getRispostePossibili(domanda),
        domanda: domanda
      };
    }
  });

  return keysMap;
}

function _valueFound(v, values, domanda) {
  if (values === false) {
    return true;
  }
  var found;
  if (values === true) {
    found = !!v;
  } else if (values.domandaNumber) {
    if (typeof v !== "number") {
      found = false;
    } else {
      if (v < values.from || v > values.to) {
        found = false;
      } else {
        found = true;
      }
    }
  } else {
    if (
      domanda.type ===
      "radio-button-multiple-answers" /*|| domanda.type === 'group-table-row-radio-button-multi'*/
    ) {
      if (typeof v === "number") {
        v = "" + v;
      }
      if (!v) {
        found = false;
      } else {
        const vs = v.split(",").filter(x => x);
        values = values.map(x => "" + x);
        found =
          vs.length > 0 &&
          every(vs, v => {
            return values.indexOf(v) !== -1;
          });
      }
    } else {
      found = values.indexOf(v) !== -1;
    }
  }
  return found;
}

export function domandaMissingRisposta(domanda, risposte) {
  if (domanda.key) {
    const v = risposte && risposte[domanda.key];
    const values = getRispostePossibili(domanda);
    var found = _valueFound(v, values, domanda);
    return !found;
  }
  return false;
}

export function getPercentualeCompletata(questionarioData) {
  const keysWithValues = getDomandeKeysWithValues(questionarioData);
  const risposte = questionarioData.risposte;

  let done = 0;
  let total = 0;

  let insideDiarioOre = []; // 0-24

  each(keysWithValues, ({ rispostePossibili: values, domanda }, key) => {
    if (values === false) {
      return;
    }
    const v = risposte && risposte[key];

    if (domanda.type === "group-table-row-radio-button-multi") {
      // DIARIO
      if (domanda.firstOfDiario) {
        insideDiarioOre = new Array(24).map(x => false);
        if (domanda.lastOfDiario) {
          // se è anche l'ultima, vuol dire che non è obbligatorio rispondere!
          return;
        } else {
          total += 24;
        }
      }
      // nel diario, si conta se ci sono tutte le ore!
      const splitted = v
        ? (typeof v === "number" ? "" + v : v)
            .split(",")
            .filter(x => x)
            .map(x => parseInt(x))
        : [];
      splitted.forEach(h => {
        if (!insideDiarioOre[h]) {
          insideDiarioOre[h] = true;
          done++;
        }
      });
    } else {
      total++;
      var found = _valueFound(v, values, domanda);
      if (found) {
        done++;
      }
    }
  });

  return (done / (total || 1)) * 100;
}

export function checkForRisposteAuto(questionarioData, risposte) {
  forEachDomanda(questionarioData, domanda => {
    if (domanda.key) {
      if (domanda.auto) {
        let ok = true;
        if (domanda.auto.sum) {
          const divideBy = domanda.auto.divideBy;
          const specialCases = domanda.auto.specialCases || [];
          for (let index = 0; index < specialCases.length; index++) {
            const specialCase = specialCases[index];
            let ifCondResult = true;
            for (let index2 = 0; index2 < specialCase.if.length; index2++) {
              const ifCond = specialCase.if[index2];
              const risposta = risposte[ifCond.key];
              console.log("***R", risposta, ifCond.value);
              if (risposta !== ifCond.value) {
                ifCondResult = false;
                break;
              }
            }
            if (ifCondResult) {
              risposte[domanda.key] = specialCase.result;
              return;
            }
          }
          const values = domanda.auto.sum.map(x => {
            let v;
            if (typeof x === "object" && x.map) {
              v = risposte[x.key];
              v = x.map[v];
            } else if (typeof x === "object" && x.custom) {
              if (x.custom === "età>=50") {
                // const dataDiNascita = questionarioData.user.dataDiNascita
                // const dataDiRiferimento = '2020-09-01'
                // const diff = moment(dataDiRiferimento).diff(dataDiNascita, 'years')
                const diff = risposte["Charlson_20"];
                if (typeof diff === "number") {
                  if (diff >= 50) {
                    v = 1;
                  } else {
                    v = 0;
                  }
                }
              } else {
                throw new Error("not implemented: " + x.custom);
              }
            } else {
              v = risposte[x];
            }
            if (typeof v === "undefined") {
              ok = false;
            }

            if (typeof x === "object" && x.onlyIf) {
              const ifV = risposte[x.onlyIf.key];
              const expectedV = x.onlyIf.value;
              if (typeof ifV === "undefined") {
                ok = false;
              }
              if (ifV !== expectedV) {
                v = x.onlyIf.elseValue;
              }
            }

            return v || 0;
          });
          let sum = values.reduce((s, v) => s + v, 0);
          if (typeof divideBy === "number") {
            sum = sum / divideBy;
          }
          risposte[domanda.key] = ok ? sum : undefined;
        } else if (domanda.auto.specialType === "BMI") {
          const m = risposte[domanda.auto.massa];
          const h = risposte[domanda.auto.altezza];
          const factor = domanda.auto.factor || 1;
          if (typeof m === "undefined") {
            ok = false;
          }
          if (typeof h === "undefined") {
            ok = false;
          }
          risposte[domanda.key] = ok ? (m / h / h) * factor : undefined;
        }
      }
    }
  });
}
