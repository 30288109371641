import { some } from "lodash";
import { tipiControlli, tipiPazienti } from "../tipiPazienti.js";

export const questionari = {
  // "questionario_test_1": require('./questionariObjects/__test1').default,
  // "questionario_test_2": require('./questionariObjects/__test2').default,
  // "questionario_test_3": require('./questionariObjects/__test3').default,
  // "questionario_test_4": require('./questionariObjects/__test4').default,
  // "questionario_test_5": require('./questionariObjects/__test5').default,
};

const questionariGenerated = require("./questionariObjects/generated.js")
  .default;

const _questionariPerOperatore = [];

const _questionariPerPazienteByType = {};
tipiPazienti.forEach(x => {
  _questionariPerPazienteByType[x] = [];
});
tipiControlli.forEach(x => {
  _questionariPerPazienteByType[x] = [];
});

questionariGenerated.forEach(questionario => {
  questionari[questionario.type] = questionario;

  const filtro =
    questionario.filtro && questionario.filtro.length > 0
      ? questionario.filtro
      : null;

  tipiPazienti.forEach(x => {
    if (
      !filtro ||
      some(filtro, f => f.key === "USER_INFO_userType" && f.value === x)
    ) {
      _questionariPerPazienteByType[x].push(questionario);
    }
  });

  tipiControlli.forEach(x => {
    if (
      !filtro ||
      some(filtro, f => f.key === "USER_INFO_userType" && f.value === x)
    ) {
      _questionariPerPazienteByType[x].push(questionario);
    }
  });

  if (
    !filtro ||
    some(filtro, f => f.key === "USER_INFO_userType" && f.value === "Operatore")
  ) {
    _questionariPerOperatore.push(questionario);
  }
});

export const questionariPerPaziente = [
  //TODO: solo quelli di quel tipo soggetto
  ...questionariGenerated.map(q => q.type)
].map(getQuestionarioByKey);

export const questionariPerOperatore = _questionariPerOperatore;

export function getQuestionarioByKey(key) {
  return questionari[key];
}

const mmse_total_MIN = 24;

export function getQuestionariPerPaziente(user) {
  const type = user.userType;
  let array = _questionariPerPazienteByType[type] || [];

  // if (tipiPazienti.indexOf(user.userType) !== -1) {
  //   if (!user.mmse_total || user.mmse_total < mmse_total_MIN) {
  //     return array.filter((q) => {
  //       return q.type === "SCHEDE SOGGETTO" || q.type === "MMSE";
  //     });
  //   }
  // }

  return array;
}

export function getQuestionariPerPazienteWithType(type) {
  return _questionariPerPazienteByType[type] || [];
}
