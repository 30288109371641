import React from "react";
import { Component } from "react";
import { getQuestionarioByKey } from "common/utils/questionari.js";
import { get } from "lodash";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  Alert,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody
} from "reactstrap";
import {
  getRisposteQuestionario,
  updateRisposteQuestionario
} from "app/actions";
import QuestionarioForm from "app/components/QuestionarioForm.js";
import QuestionarioBottomStateBar from "app/elements/QuestionarioBottomStateBar.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { tipiControlli } from "common/tipiPazienti";

class Questionario extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onNewRisposte = (newRisposte, data, setData) => {
    const newData = {
      ...data,
      risposte: newRisposte
    };

    setData(newData);
  };

  render() {
    const { t } = this.props;
    return getQuestionarioByKey(get(this.props, "questionarioKey")) ? (
      <AsyncData
        loaderAction={this.props.getRisposteQuestionario}
        loaderArgs={[
          get(this.props, "userId"),
          get(this.props, "questionarioKey")
        ]}
        saveAction={this.props.updateRisposteQuestionario}
      >
        {({
          loading,
          loadingError,
          data,
          setData,
          hasChanges,
          saveData,
          saving,
          savingError,
          cancelChanges
        }) => {
          console.log({
            loading,
            loadingError,
            data,
            hasChanges,
            saving,
            savingError
          });
          if (loading) {
            return <Loading />;
          }
          if (loadingError) {
            return <Alert color="danger">{loadingError.message}</Alert>;
          }
          return (
            <>
              <Row style={{ marginBottom: 90 }}>
                <Col sm={12}>
                  <Card>
                    <CardHeader>
                      {t("questionario_label")}:{" "}
                      {getQuestionarioByKey(
                        get(this.props, "questionarioKey")
                      ) ? (
                        <h4 style={{ display: "inline-block" }}>
                          {get(
                            getQuestionarioByKey(
                              get(this.props, "questionarioKey")
                            ),
                            "name"
                          )}
                        </h4>
                      ) : null}
                      <br />
                      {t("soggetto_label")}:{" "}
                      <h4 style={{ display: "inline-block" }}>
                        {get(data, "user.codiceUtente") || null}
                      </h4>
                      {get(data, "user.ritirato") ? (
                        <span className="text-info" style={{ marginLeft: 20 }}>
                          {t("soggetto_si_e_ritirato_label")}{" "}
                        </span>
                      ) : null}
                      <div className="float-right">
                        {tipiControlli.indexOf(get(data, "user.userType")) !==
                        -1 ? (
                          <Button
                            color="info"
                            size="sm"
                            outline={true}
                            onClick={() => {
                              this.props.history.push(
                                "/controlli/" +
                                  get(this.props, "userId") +
                                  "/dettaglio"
                              );
                            }}
                          >
                            {t("torna_alla_pagina_controllo")}{" "}
                          </Button>
                        ) : get(data, "user.userType") == "Operatore" ? (
                          <Button
                            color="info"
                            size="sm"
                            outline={true}
                            onClick={() => {
                              this.props.history.push(
                                "/operatori/" +
                                  get(this.props, "userId") +
                                  "/dettaglio"
                              );
                            }}
                          >
                            {t("torna_alla_pagina_operatore")}{" "}
                          </Button>
                        ) : (
                          <Button
                            color="info"
                            size="sm"
                            outline={true}
                            onClick={() => {
                              this.props.history.push(
                                "/pazienti/" +
                                  get(this.props, "userId") +
                                  "/dettaglio"
                              );
                            }}
                          >
                            {t("torna_alla_pagina_paziente")}{" "}
                          </Button>
                        )}
                      </div>
                    </CardHeader>

                    <CardBody>
                      <Row>
                        <Col>
                          <QuestionarioForm
                            questionario={getQuestionarioByKey(
                              get(this.props, "questionarioKey")
                            )}
                            questionarioData={data}
                            updateRisposteQuestionario={newRisposte => {
                              this.onNewRisposte(newRisposte, data, setData);
                            }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <QuestionarioBottomStateBar
                userId={get(this.props, "userId")}
                questionarioKey={get(this.props, "questionarioKey")}
                data={data}
                hasChanges={hasChanges}
                saveData={saveData}
                saving={saving}
                savingError={savingError}
              />
            </>
          );
        }}
      </AsyncData>
    ) : (
      <Alert color="danger">
        {t("il_questionario_non_esiste")}: {get(this.props, "questionarioKey")}
      </Alert>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: get(ownProps, "match.params.userId"),
    questionarioKey: get(ownProps, "match.params.questionarioKey")
  };
};
const mapDispatchToProps = {
  getRisposteQuestionario: getRisposteQuestionario,
  updateRisposteQuestionario: updateRisposteQuestionario
};

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Questionario)
  )
);
