import React from "react";
import { Component } from "react";
import { get } from "lodash";
import {
  getOperatori,
  getCompletamentoQuestionariPerUtente,
  creaOperatore
} from "app/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AbstractListaPartecipanti from "./AbstractListaPartecipanti";

class ListaOperatori extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AbstractListaPartecipanti
        getPartecipanti={this.props.getOperatori}
        isOperatori
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getOperatori: getOperatori,
  getCompletamentoQuestionariPerUtente: getCompletamentoQuestionariPerUtente,
  creaOperatore: creaOperatore
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListaOperatori)
);
