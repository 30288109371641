export const codiciCentri = [
  {
    codiceCentro: "BS01",
    nomeCentro: "IRCCS Fatebenefratelli Brescia",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "MI01",
    nomeCentro: "Fondazione IRCCS Ca' Granda Policlinico",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "SI01",
    nomeCentro: "AOU Policlinico Le Scotte",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "CR01",
    nomeCentro: "DSMD ASST di Cremona",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "GE01",
    nomeCentro: "University Hospital Frankfurt",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "GE02",
    nomeCentro: "University Hospital Karl Gustav ",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "NO01",
    nomeCentro: "University of Oslo",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "FR01",
    nomeCentro: "Universite de Paris",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "FR02",
    nomeCentro: "Centre Expert Bipolaire - CHU de Montpellier",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "FR03",
    nomeCentro: "Centre Expert Bipolaire - Hopital Albert Chenevier",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "SP01",
    nomeCentro: "Hospital de la Santa Creu i Sant Pau",
    haControlli: true,
    haEsmActigrafo: true
  }
];
