import React from "react";
import { Component } from "react";
import PageHeader from "app/pages/Header";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { get } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle as farUserCircle } from "@fortawesome/free-regular-svg-icons/faUserCircle";
import { faLock as fasLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { logout } from "app/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ICON } from "common/datiPortale";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logout = () => {
    this.props.logout();
  };

  render() {
    const { t } = this.props;
    return (
      <PageHeader
        hasSidebar={this.props.hasSidebar}
        icon={<img style={{ height: "50%" }} src={ICON} />}
        leftMenu={<div />}
        rightMenu={
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                {get(this.props, "codiceUtente")} {get(this.props, "userName")}{" "}
                {get(this.props, "userLastName")}{" "}
                <FontAwesomeIcon icon={farUserCircle} size={"lg"} />
              </span>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  this.logout();
                }}
              >
                <FontAwesomeIcon icon={fasLock} />
                {t("logout_label")}{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userName: get(state, "auth.user.name"),
    userLastName: get(state, "auth.user.lastName"),
    codiceUtente: get(state, "auth.user.codiceUtente")
  };
};
const mapDispatchToProps = {
  logout: logout
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
