import React from "react";
import RangeSlider from "react-bootstrap-range-slider";
import { withTranslation } from "react-i18next";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

const noop = () => null;

class Barra0_100 extends React.Component {
  render() {
    const { t } = this.props;

    let value = this.props.value;
    const onChange = this.props.onChange;

    const defaultValue = 50;

    let init = noop;

    let className = "barra0-100";
    if (typeof value !== "number") {
      value = defaultValue;
      className += " barra0-100-not-selected";
      init = () => {
        onChange(defaultValue);
      };
    }

    return (
      <div className={className}>
        <RangeSlider
          value={value}
          onChange={changeEvent => {
            const v = changeEvent.target.value;
            onChange(parseInt(v, 10));
          }}
          inputProps={{
            onTouchStart: init
          }}
          min={0}
          max={100}
          size="lg"
        />
        <div className="barra-estremi">
          <span>{t("mobile:barra_estremi_per_nulla")}</span>
          <span>{t("mobile:barra_estremi_tantissimo")}</span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Barra0_100);
