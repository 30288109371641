import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import it from "./translations/it.json";
import en from "./translations/en.json";
import es from "./translations/es.json";
import de from "./translations/de.json";
import fr from "./translations/fr.json";
import { LNG, SUPPORTED_LNGS } from "../common/datiPortale";

const supportedLngs = SUPPORTED_LNGS;
let lng = LNG;

const urlParams =
  typeof window !== "undefined" && new URLSearchParams(window.location.search);
const lngFromQuery = urlParams && urlParams.get("lng");

if (lngFromQuery) {
  if (supportedLngs.indexOf(lngFromQuery) !== -1) {
    lng = lngFromQuery;
  }
}

console.log("LNG: " + lng);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    lng: lng,
    fallbackLng: lng,
    compatibilityJSON: "v3",
    // the translations
    resources: {
      en: en,
      it: it,
      es: es,
      de: de,
      fr: fr
    },
    supportedLngs: supportedLngs,
    nonExplicitSupportedLngs: true,
    ns: ["translation"],
    defaultNS: "translation",
    interpolation: {
      escapeValue: false // not needed for react
    },
    initImmediate: true
  });

export default i18n;
