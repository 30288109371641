import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { questionariPerOperatore, getQuestionariPerPaziente } from "../../common/utils/questionari";
// import {
//   Link
// } from "react-router-dom"
// import { questionariFacoltativi } from "../../common/utils/questionarioCheck";
import { deleteUser } from "app/actions";
import { withTranslation } from "react-i18next";

import "./DeleteUser.scss";

class DeleteUser extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  showConfirm = () => {
    this.setState({
      deleting: false,
      showConfirm: true
    });
  };

  hideConfirm = () => {
    this.setState({
      deleting: false,
      showConfirm: false
    });
  };

  doDelete = () => {
    const { t } = this.props;
    this.setState({
      deleting: true
    });
    this.props.deleteUser(this.props.user.id).then(action => {
      if (action.callApiSuccess) {
        window.alert(t("partecipante_eliminato_label"));
        this.redirect();
      } else {
        window.alert(t("errore_durante_eliminazione_label"));
        this.setState({
          deleting: false
        });
      }
    });
  };

  redirect = () => {
    let url = window.location.pathname;
    let slashIndex = url.indexOf("/", 1);
    if (slashIndex !== -1) {
      url = url.substring(0, slashIndex);
    } else {
      url = "/";
    }
    this.props.history.push(url);
  };

  render() {
    const { t, isAdmin, user } = this.props;
    const { showConfirm, deleting } = this.state;

    // MODIFICA BIPCOM: TUTTI POSSONO CANCELLARE
    // if (!isAdmin) {
    //   return null;
    // }

    return (
      <React.Fragment>
        {" "}
        <Button
          color="danger"
          size="sm"
          outline={true}
          onClick={() => {
            this.showConfirm();
          }}
        >
          {t("elimina_partecipante_label")}{" "}
        </Button>{" "}
        <Modal size="lg" isOpen={showConfirm}>
          <ModalHeader
            toggle={() => {
              if (deleting) {
                return;
              }
              this.hideConfirm();
            }}
          >
            {t("elimina_partecipante_label")}{" "}
          </ModalHeader>

          <ModalBody>{t("elimina_partecipante_conferma_label")}</ModalBody>

          <ModalFooter>
            <Button
              color="secondary"
              disabled={deleting}
              onClick={() => {
                this.hideConfirm();
              }}
            >
              {t("annulla_label")}{" "}
            </Button>

            <Button
              color="danger"
              disabled={deleting}
              onClick={() => {
                this.doDelete();
              }}
            >
              {t("elimina_partecipante_conferma_button_label")}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAdmin:
      state.auth && state.auth.user && state.auth.user.userType === "Admin"
  };
};
const mapDispatchToProps = {
  deleteUser: deleteUser
};

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DeleteUser)
  )
);
