import React from "react";
import { Component } from "react";
import { Badge } from "reactstrap";
import { withTranslation } from "react-i18next";

import "./LegendaColoriQuestionari.scss";

class LegendaColoriQuestionari extends Component {
  render() {
    const { t } = this.props;

    const colori = [
      {
        color: "success",
        descrizione: t("completato_label")
      },
      {
        color: "warning",
        descrizione: t("iniziato_ma_non_completato_label")
      },
      {
        color: "danger",
        descrizione: t("non_iniziato_label")
      }
    ];

    return (
      <div className="legenda-colori-questionari">
        {colori.map((coloreObject, i) => {
          return (
            <div key={i} className="legenda-colori-questionari-item">
              <Badge color={coloreObject.color}> </Badge>
              <span className="legenda-colori-questionari-item--descrizione">
                {coloreObject.descrizione}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(LegendaColoriQuestionari);
