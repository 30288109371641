import React from "react";
import { Component } from "react";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  Alert,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody
} from "reactstrap";
import { getPaziente, updatePaziente } from "app/actions";
import { get } from "lodash";
import PreventClose from "app/elements/PreventClose.js";
import DeleteUser from "app/elements/DeleteUser.js";
import PazienteForm from "app/components/PazienteForm.js";
import Obiettivo2 from "app/components/Obiettivo2.js";
import LegendaColoriQuestionari from "app/elements/LegendaColoriQuestionari.js";
import ListaQuestionariUtente from "app/components/ListaQuestionariUtente.js";
import { getQuestionariPerPaziente } from "common/utils/questionari.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import AbstractDettaglioPartecipante from "./AbstractDettaglioPartecipante";

class DettaglioPaziente extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AbstractDettaglioPartecipante
        FormComponent={PazienteForm}
        isOperatore={false}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: get(ownProps, "match.params.id")
  };
};
const mapDispatchToProps = {
  getPaziente: getPaziente,
  updatePaziente: updatePaziente
};

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DettaglioPaziente)
  )
);
