import React from "react";
import { Component } from "react";
import PageHeader from "app/pages/Header";
import { ICON } from "common/datiPortale";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <PageHeader
        hasSidebar={this.props.hasSidebar}
        icon={<img style={{ height: "50%" }} src={ICON} />}
        leftMenu={<div />}
      />
    );
  }
}

export default Header;
