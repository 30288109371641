const { PROJECT_ID } = require("../../datiPortale");

let generated;

if (PROJECT_ID == "bipcom") {
  generated = require("./generated-bipcom");
} else if (PROJECT_ID == "bipcom-mrat") {
  generated = require("./generated-bipcom-mrat");
} else if (PROJECT_ID == "riskmet") {
  generated = require("./generated-riskmet");
} else {
  console.log("NOT DONE YET - generated ??? ");
  process.exit(1);
}

export default generated;
